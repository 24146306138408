import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'

import { routes } from '../constants/routes'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { handleFirebaseAuth, removeAuth, saveAnonymousAuth, killState } from '../actions/auth'

import AuthRouter from './public/auth/auth-router'
import PageLoader from './public/pages/loader'

import { isEmpty } from 'lodash'
import DevTools from './utils/dev-tools'

import '../assets/scss/app.scss'

// Font Awesome as needed
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUserCog, faTimes, faCheck, faCaretDown, faEdit, faChartPie, faGasPump, faTruck, faMicrochip, faArrowRight, faSearch, faInfoCircle, faCheckCircle, faPencilAlt, faSave, faSpinner, faPlus,
    faToggleOn, faToggleOff, faEye, faEyeSlash, faChartLine, faUsers, faTachometerAlt, faCog, faList, faTrashAlt, faUserShield, faDownload, faExclamationTriangle, faUserCircle, faUpload, faSync
} from '@fortawesome/free-solid-svg-icons'
library.add(
    faUserCog, faTimes, faCheck, faCaretDown, faEdit, faChartPie, faGasPump, faTruck, faMicrochip, faArrowRight, faSearch, faInfoCircle, faCheckCircle, faPencilAlt, faSave, faSpinner, faPlus,
    faToggleOn, faToggleOff, faEye, faEyeSlash, faChartLine, faUsers, faTachometerAlt, faCog, faList, faTrashAlt, faUserShield, faDownload, faExclamationTriangle, faUserCircle, faUpload, faSync
)

const AdminIndex = lazy(() => import('./admin/index'))
const AppIndex = lazy(() => import('./app/index'))
const PublicIndex = lazy(() => import('./public/index'))

const ROUTER_PAGES = [routes.authLogin.path, routes.router.path, routes.authRegister.path, routes.authPasswordReset.path]

function AppRoute({ children, auth, location }) {
    let isAuthenticated = (!isEmpty(auth) && !auth.isAnonymous) || false
    return isAuthenticated ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

function AdminRoute({ children, auth, user, location }) {
    let isAuthenticated = (!isEmpty(auth) && !auth.isAnonymous) || false
    let isAdmin = (!isEmpty(user) && user?._computed?.roleLevel >= 1000) || false
    return isAuthenticated && isAdmin ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

class App extends React.Component {
    state = {
        hasAuthRouted: false,
        fireauth: null,
        shouldRoute: false
    }

    triggerAuthRouter = () => {
        const fromState = this.props.location.state && this.props.location.state.from.pathname
            ? this.props.location.state.from.pathname
            : null
        return <Navigate to={ routes.router.path } state={{ from: { pathname: fromState } }} />
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        const fireauth = getAuth(this.props.firebaseApp)
        this.setState({ fireauth })
        onAuthStateChanged(fireauth, firebaseUser => {
            if (firebaseUser) {
                if (firebaseUser.isAnonymous) {
                    dispatch(saveAnonymousAuth(fireauth, firebaseUser))
                } else {
                    dispatch(handleFirebaseAuth(firebaseUser.toJSON()))
                    const pathname = this.props.location.pathname
                    if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                        pathname.indexOf('/app/') !== -1 ||
                        pathname.indexOf('/admin/') !== -1
                    ) {
                        this.setState({ hasAuthRouted: true })
                        this.setState({ shouldRoute: true })
                    }
                }
            } else {
                dispatch(killState())
                dispatch(removeAuth())
                this.setState({ hasAuthRouted: false })
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.hasAuthRouted && !isEmpty(this.props.auth)) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                const pathname = this.props.location.pathname
                if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                    pathname.indexOf('/app/') !== -1 ||
                    pathname.indexOf('/admin/') !== -1
                ) {
                    this.setState({ hasAuthRouted: true })
                    this.setState({ shouldRoute: true })
                }
            }
        }
        if (this.state.hasAuthRouted && this.state.shouldRoute) {
            this.setState({ shouldRoute: false })
        }
    }

    render = () => {
        const { auth, user } = this.props

        if (this.state.shouldRoute) {
            return this.triggerAuthRouter()
        }

        return (
            <>
                <Suspense fallback={<PageLoader />}>
                    <Routes>
                        <Route path={`${routes.appIndex.path}*`} element={<AppRoute auth={auth} location={this.props.location}><AppIndex /></AppRoute>} />
                        <Route path={`${routes.adminIndex.path}*`} element={<AdminRoute auth={auth} user={user} location={this.props.location}><AdminIndex fireauth={this.state.fireauth} /></AdminRoute>} />
                        <Route path={routes.router.path} element={<AppRoute auth={auth} location={this.props.location}><AuthRouter /></AppRoute>} />
                        <Route path="*" element={<PublicIndex fireauth={this.state.fireauth} />} />
                    </Routes>
                    <DevTools />
                </Suspense>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user
    }
}

export default withRouterProps(connect(mapStateToProps)(App))
